import React from 'react';
import './App.module.scss';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import configureStore from './store/configureStore';
import routes from './routes';
import TabsRoutes from './TabsRoutes';
import setIsLogged from './actions/isLoggedActions';
import { lazyLoadComponent } from './components/lazyLoadComponent';


const { store } = configureStore();
store.dispatch(setIsLogged());


function App() {
  const { isLogged } = configureStore().store.getState();

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          {!isLogged && (
            <Route
              path="/"
              component={lazyLoadComponent(() => import('./screens/SignUpScreen'))}
            />
          )}
          {routes.modals.map(({
            path, exact, component,
          }) => {
            return (
              <Route
                key={`${path}-${component}`}
                path={path}
                exact={exact}
                component={component}
              />
            );
          })}
          {isLogged && (<TabsRoutes />)}
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;

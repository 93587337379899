import SET_IS_LOGGED from '../actions/actionTypes';


// TODO: Move to prop of trips, trips.selected
export default function SetIsLoggedReducer(state = false, action) {
  switch (action.type) {
    case SET_IS_LOGGED: {
      return !state;
    }
    default:
      return state;
  }
}
